.file-type-icon {
  position: relative;
}

.file-type-icon svg {
  width: 38px;
  height: auto;
}

.file-type-icon-text {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.6rem;
  font-weight: 500;
  line-height: 1;
  color: var(--white);
}
