@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';

@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.rdw-editor-wrapper {
  .rdw-editor-toolbar {
    margin-bottom: 0;
    padding: 0.5rem;
    padding-bottom: 0;
    border-color: $border-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    .rdw-option-wrapper {
      border: none;
      &.rdw-option-active {
        box-shadow: none;
        background-color: rgba($color: $primary, $alpha: 0.2);
      }
      &:hover {
        box-shadow: none;
      }
    }
    .rdw-dropdown-wrapper {
      border: 1px solid $border-color;
      .rdw-dropdown-optionwrapper {
        &:hover {
          box-shadow: none;
        }
      }
      &:hover {
        box-shadow: none;
      }
    }
    .rdw-link-modal,
    .rdw-embedded-modal {
      height: auto;
    }
  }
  .rdw-editor-main {
    //background-color: $white;
    min-height: 10rem;
    padding: 0.5rem 1.2rem;
    border: 1px solid $border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  .rdw-editor-toolbar ~ .rdw-editor-main {
    border-top: none;
  }

  &.toolbar-bottom {
    display: flex;
    flex-direction: column;
    .rdw-editor-toolbar {
      order: 2;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    .rdw-editor-main {
      border-top: 1px solid $border-color;
      border-bottom: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      order: 1;
    }
  }
}
