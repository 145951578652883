/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/colors';
@import '../../@core/scss/base/components/_variables-dark.scss';

:root {
  --light-purple: rgba(115, 103, 240, 0.15);
  --light-indigo: rgba(102, 16, 242, 0.15);
  --light-pink: rgba(232, 62, 140, 0.15);
  --light-red: rgba(220, 53, 69, 0.15);
  --light-orange: rgba(253, 126, 20, 0.15);
  --light-yellow: rgba(255, 193, 7, 0.15);
  --light-teal: rgba(32, 201, 151, 0.15);
  --light-green: rgba(40, 167, 69, 0.15);
  --light-cyan: rgba(23, 162, 184, 0.15);
  --light-blue: rgba(0, 123, 255, 0.15);

  --light-primary: rgba(240, 21, 41, 0.12);
  --light-secondary: rgba(130, 134, 139, 0.12);
  --light-warning: rgba(255, 159, 67, 0.12);
  --light-default-blue: rgba(55, 136, 216, 0.12);
  --light-danger: rgba(220, 53, 69, 0.12);

  --xtra-light-primary: rgba(240, 21, 41, 0.05);
  --xtra-light-secondary: rgba(130, 134, 139, 0.05);
  --xtra-light-warning: rgba(255, 159, 67, 0.05);
  --xtra-light-default-blue: rgba(55, 136, 216, 0.05);
  --xtra-light-danger: rgba(220, 53, 69, 0.05);

  --primary: #f01529;
  --border: #ebe9f1;
  --dark-border: #3b4253;
  --body-bg: #f8f8f8;
  --dark-body-bg: #161d31;
  --card-bg: #fff;
  --dark-card-bg: #283046;
  --font-color: #6e6b7b;
  --dark-font-color: #fff;
  --text-muted: #b9b9c3;
  --dark-text-muted: #676d7d;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 10px;
  }
}

.react-player-wrapper {
  border-radius: 0.428rem;
  box-shadow: none;
}

.react-player-video {
  video {
    border-radius: 0.428rem;
  }
}

@media (max-width: 768px) {
  ._w-xs-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 768px) and (max-width: 1400px) {
  ._w-md-100 {
    max-width: 70%;
    flex: 0 0 70%;
  }
}

.dropzone {
  border: 2px dashed rgba($dark, 0.2);
  border-radius: 6px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

.dropzone-error {
  border: 2px dashed $danger;
  border-radius: 6px;
}

.input-group-text-borders {
  border-radius: 0.357rem 0 0 0.357rem !important;
  border-right: none !important;
}

.form-group-w-input-group--left>input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-group-w-input-group--right>input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.navigation li a svg {
  height: 18px;
  width: 18px;
}

.card-bg {
  background-color: $card-bg !important;
}

.dark-layout .card-bg {
  background-color: $theme-dark-card-bg !important;
}

.layout-bg {
  background-color: $body-bg !important;
}

.dark-layout .layout-bg {
  background-color: $theme-dark-body-bg !important;
}

.border-color {
  border-color: $border-color !important;
}

.dark-layout .border-color {
  border-color: $theme-dark-border-color !important;
}

.custom-file-card {
  &--header {
    opacity: 0;
  }
}

.custom-file-card {
  &:hover {
    .custom-file-card--header {
      opacity: 1;
    }
  }
}

.truncate-lines-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.truncate-lines-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.truncate-lines-15 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
}

.custom-scrollbar {
  overflow-y: auto;
}

.custom-hover-shadow {
  transition: all 0.2s ease-in-out;
  border: 1px solid;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 #898989;
    border: none;
  }
}

.dark-layout .custom-hover-shadow {
  &:hover {
    box-shadow: 0 4px 25px 0 #1e1e1e;
  }
}

.custom-additional-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f4f4f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 15px;
  max-width: 225px;
  margin: 0 auto;
}

.dark-layout .custom-additional-box {
  background-color: lighten($theme-dark-card-bg, 3%);
}

.progress-step-list-item::before {
  display: inline-block;
  content: '';
  border-top: 0.3rem solid;
  // width: 7rem;
  width: 100%;
  transform: translateY(-0.75rem);
  margin-top: 42px;
}

.progress-step-list {
  width: 100%;
  justify-content: center;
}

.progress-step-list-item>div {
  align-items: start;
}

.text-regular {
  color: var(--font-color);
}

.dark-layout {
  .text-regular {
    color: var(--dark-font-color);
  }
}

@media (max-width: 768px) {
  .progress-step-list {
    flex-direction: column;
    width: auto;
  }

  >div {
    align-items: center;
  }

  .progress-step-list-item {
    flex-direction: column;

    &::before {
      transform: none;
      border-right: 0.3rem solid;
      height: 45px;
      border-top: 0;
      width: 50%;
      margin-top: 0;
      margin-left: 3px;
    }
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 428px) {
  .btn-for-mobile {
    padding: 0.5rem !important;
    font-size: 0.85rem;
  }
}

.custom-checkbox>label {
  cursor: pointer;
}

@keyframes animate-pulse {
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: animate-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.custom-radio .custom-control-input:checked~.custom-control-label {
  font-weight: 600;
}

.customGreen.custom-radio .custom-control-input:not(:disabled):active~.custom-control-label::before,
.customGreen.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: none !important;
}

.customGreen.custom-radio .custom-control-input:checked~.custom-control-label {
  color: #28a745;
}

.customRed.custom-radio .custom-control-input:not(:disabled):active~.custom-control-label::before,
.customRed.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #f01529;
  border-color: #f01529;
  box-shadow: none !important;
}

.customRed.custom-radio .custom-control-input:checked~.custom-control-label {
  color: #f01529;
}

.customOrange.custom-radio .custom-control-input:not(:disabled):active~.custom-control-label::before,
.customOrange.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #ff9f43;
  border-color: #ff9f43;
  box-shadow: none !important;
}

.customOrange.custom-radio .custom-control-input:checked~.custom-control-label {
  color: #ff9f43;
}

.customGrey.custom-radio .custom-control-input:not(:disabled):active~.custom-control-label::before,
.customGrey.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: none !important;
}

.customGrey.custom-radio .custom-control-input:checked~.custom-control-label {
  color: #6c757d;
}

@media screen and (max-width: 575px) {
  .c-attendance-students-list {
    height: calc(100% - 259px) !important;
  }

  //.c-assessment-students-list {
  //  height: calc(100% - 4.9rem) !important;
  //}
}

.break-spaces {
  white-space: break-spaces;
}

.cursor-pointer {
  cursor: pointer !important;
}

.mantine-RichTextEditor-typographyStylesProvider {
  height: 100%;
}

.mantine-RichTextEditor-content {
  min-height: 180px;
}